<template>
    <div>
        <vs-row vs-w="12">
  <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="2" vs-sm="4" vs-xs="12" >
  </vs-col>
  <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="8" vs-sm="4" vs-xs="12">
    <br/>
    <div class="mt-8">
        <p><strong>T&Eacute;RMINOS Y CONDICIONES</strong></p>
<p>Le solicitamos al usuario de este sitio web que lea detallada y cuidadosamente este documento antes de iniciar su exploraci&oacute;n o utilizaci&oacute;n. Si no est&aacute; de acuerdo con lo establecido, por favor abst&eacute;ngase de acceder a este sitio y sus canales ya sea directa o indirectamente, y de utilizar cualquier informaci&oacute;n o servicio provisto por el mismo. Flattlo como marca comercial de Flattlo puede limitar el contenido y la descarga de cierto material a personas seg&uacute;n su criterio.</p>
<p>Se presume la aceptaci&oacute;n de las condiciones de uso establecidas en este documento, cuando el usuario consulte cualquiera de los contenidos del sitio web. Previo al suministro de informaci&oacute;n y/o datos personales por parte del usuario a trav&eacute;s de los servicios que el sitio web ofrece, este aceptar&aacute;, en la forma establecida para el efecto, la pol&iacute;tica de tratamiento de datos personales de Flattlo.</p>
<p>Flattlo se reserva el derecho de actualizar y modificar las presentes condiciones de uso, contenidos y servicios ofrecidos por el sitio web, unilateralmente, por cualquier causa, en cualquier momento, de cualquier forma, sin previo aviso y sin que ello d&eacute; lugar a indemnizaci&oacute;n alguna. Dichas modificaciones entrar&aacute;n en vigencia una vez sean publicadas en el sitio web.</p>
<p>El usuario se compromete a revisar peri&oacute;dicamente el presente documento para estar informado de las modificaciones introducidas. Cada nuevo acceso del usuario al sitio web, constituye una aceptaci&oacute;n t&aacute;cita de las condiciones de uso establecidas en este documento.</p>
<ol>
<li><strong> CONDICIONES DE USO.</strong></li>
</ol>
<p><strong>1.1 Definiciones.</strong></p>
<p>Para la aplicaci&oacute;n y prop&oacute;sitos de este documento, se entiende por:</p>
<ul>
<li><strong>Contenidos:</strong>&nbsp;todas las formas de informaci&oacute;n o datos que se divulguen en el sitio web, incluyendo, pero sin limitarse a, textos, im&aacute;genes, fotos, logos, dise&ntilde;os, animaciones, videos y audios.</li>
<li><strong>Servicios:</strong>&nbsp;servicios en l&iacute;nea que se provean actualmente en el sitio web, o que se pretendan proveer a futuro.</li>
<li><strong>Sitio Web:</strong>&nbsp;http://www.flattlo.com/.</li>
<li><strong>Usuario:</strong>&nbsp;persona natural o jur&iacute;dica que ingresa al Sitio Web para consultar informaci&oacute;n de su inter&eacute;s y/o para adquirir los Servicios ofrecidos.</li>
</ul>
<p><strong>1.2 Exclusi&oacute;n de garant&iacute;as y de responsabilidad.</strong></p>
<p><strong>1.2.1. General.</strong></p>
<ul>
<li>El uso que el Usuario le diere a los Contenidos del Sitio Web ser&aacute; de su exclusiva responsabilidad, en consecuencia, &eacute;ste declara de forma expresa que Flattlo no asumir&aacute; responsabilidad alguna por dicho concepto y que mantendr&aacute; indemne a esta &uacute;ltima sociedad frente a cualquier responsabilidad que se le imputar&aacute; relacionada con lo anterior.</li>
<li>Los Contenidos y Servicios del Sitio Web no tienen garant&iacute;a de ausencia de virus ni de otros elementos que puedan producir alteraciones en el sistema inform&aacute;tico del Usuario, por lo tanto, Flattlo no es responsable por los da&ntilde;os y perjuicios, cualquiera sea su naturaleza, causados con ocasi&oacute;n de dichos eventos.</li>
<li>Flattlo no garantiza la disponibilidad y continuidad del funcionamiento ininterrumpido del Sitio Web, por lo tanto, se excluye cualquier responsabilidad por los da&ntilde;os y perjuicios sufridos por el Usuario, cualquiera sea su naturaleza, que tengan origen en la falta de disponibilidad o de continuidad del funcionamiento del Sitio Web.</li>
<li>El Sitio Web podr&aacute; contener referencias y/o v&iacute;nculos a otros sitios web de propiedad de otras personas naturales o jur&iacute;dicas, lo cual no implica que Flattlo apruebe ni haga propios los contenidos ni los servicios ofrecidos por el sitio web enlazado. En consecuencia, Flattlo no asume responsabilidad alguna por el contenido ni por los servicios ofrecidos en dichos sitios web, y se excluye cualquier tipo de responsabilidad por los da&ntilde;os y perjuicios, cualquiera sea su naturaleza, ocasionados por conceptos tales como, pero sin limitarse a:</li>
<ul>
<li>El funcionamiento, disponibilidad, accesibilidad o continuidad de los sitios web enlazados.</li>
<li>La licitud, calidad, fiabilidad y mantenimiento de los contenidos y servicios de los sitios web enlazados.</li>
<li>La infracci&oacute;n de los derechos de propiedad intelectual, as&iacute; como de cualquier otro derecho, como consecuencia de los contenidos y de los servicios prestados en los sitios web enlazados.</li>
</ul>
<li>Flattlo no asume la obligaci&oacute;n de controlar al Usuario en la utilizaci&oacute;n correcta, prudente y diligente del Sitio Web, as&iacute; como de su Contenido y Servicios.</li>
<li>Flattlo se reserva el derecho a verificar la identidad del Usuario, as&iacute; como la veracidad y autenticidad de los datos que este proporcione, pero no se encuentra obligado a lo mismo. En consecuencia, Flattlo no es responsable por los da&ntilde;os y perjuicios causados al Usuario y/o a terceros, cualquiera sea su naturaleza, ocasionados por el uso indebido del Sitio Web, la falta de veracidad o autenticidad de la informaci&oacute;n que el Usuario suministre a Flattlo ni por la suplantaci&oacute;n de la identidad del Usuario.</li>
<li>Flattlo no ser&aacute; responsable por las consecuencias de los ataques o incidentes contra la seguridad del Sitio Web o contra sus sistemas de informaci&oacute;n ni por cualquier exposici&oacute;n o acceso no autorizado, fraudulento o il&iacute;cito al mismo, que puedan afectar la confidencialidad, integridad o autenticidad de la informaci&oacute;n publicada en el Sitio Web asociada a los Servicios all&iacute; ofrecidos.</li>
<li>Flattlo no se hace responsable por la informaci&oacute;n suministrada por los Usuarios a trav&eacute;s de los espacios de participaci&oacute;n del Sitio Web, tales como, pero sin limitarse a foros, tweets, chats y comentarios. En todo caso, Flattlo no usar&aacute; la informaci&oacute;n y/o datos personales contenidos en dichos espacios para fines diferentes a los autorizados previamente.</li>
</ul>
<p><strong>1.2.2. Promoci&oacute;n de inmuebles.</strong></p>
<p>Flattlo promociona a trav&eacute;s de Flattlo, proyectos inmobiliarios de diferentes empresas y toda la informaci&oacute;n contenida en el Sitio Web tiene previa autorizaci&oacute;n de dichas empresas y de las compa&ntilde;&iacute;as patrocinadoras, Flattlo no se hace responsable por el contenido, promociones y ofertas establecidas en el Sitio Web.</p>
<p>Los cupones de descuento o bonos ser&aacute;n transferidos al Usuario una vez que sean verificados los datos de contacto, en caso de entregar informaci&oacute;n falsa o incompleta, el cup&oacute;n o bono ser&aacute; anulado y no tendr&aacute; validez comercial.</p>
<p><strong>1.2.3. Servicios.</strong></p>
<p>Salvo que se establezca expresamente lo contrario, los cupones o bonos son prestados por el tercero que publicita a trav&eacute;s del Sitio Web Flattlo.</p>
<p>El cumplimiento de las obligaciones asociadas a los cupones de prestaciones de servicios o bonos, son siempre de cargo del tercero que publicita o socio, quien estar&aacute; debidamente identificado en la respectiva publicaci&oacute;n. Flattlo directamente o a trav&eacute;s de Flattlo, no es responsable por el cumplimiento de tales obligaciones ni por el contenido de dicha publicidad, siendo el tercero que publicita o su socio el &uacute;nico responsable del cumplimiento de los posibles da&ntilde;os o perjuicios que se causen al Usuario dentro del &aacute;mbito de la prestaci&oacute;n del Servicio publicitado.</p>
<p>Los descuentos incorporados en los cupones o bonos no pueden ser reembolsados total o parcialmente ni pueden ser acumulados con otras promociones que tenga vigentes el tercero que publicita.</p>
<p>Los cupones de servicios o bonos tendr&aacute;n las siguientes condiciones espec&iacute;ficas:</p>
<ul>
<li>Los cupones o bonos, su correcto uso y cuidado son responsabilidad del adquirente.</li>
</ul>
<ul>
<li>Los cupones o bonos no son nominativos, son al portador.</li>
</ul>
<ul>
<li>Los cupones de servicios o bonos s&oacute;lo podr&aacute;n ser usados hasta la fecha especificada en los mismos, despu&eacute;s de lo cual caducar&aacute;n definitivamente, sin que pueda el Usuario reclamar derecho o indemnizaci&oacute;n alguna.</li>
<li>Para validar los descuentos en las salas de negocios es necesario presentar y validar la informaci&oacute;n.</li>
</ul>
<p><strong>1.3. Acceso al Sitio Web.</strong></p>
<p>El acceso al Sitio Web se encuentra abierto al p&uacute;blico, sin embargo, para recibir los descuentos o bonos establecidos de forma voluntaria por las compa&ntilde;&iacute;as que publicitan en el Sitio Web, ser&aacute; necesario que el Usuario proporcione los datos que se solicitan en el Sitio Web, que los mismos sean ver&iacute;dicos y que cumpla con los dem&aacute;s requerimientos que se especifican en el presente documento y en el Sitio Web frente al descuento o bono especifico.</p>
<p><strong>1.4. Uso correcto del Sitio Web.</strong></p>
<p>El Usuario se compromete a utilizar el Sitio Web y los Servicios ofrecidos de conformidad con la legislaci&oacute;n aplicable, las condiciones establecidas en el presente documento, el orden p&uacute;blico, la moral y las buenas costumbres. El Usuario es el &uacute;nico responsable del uso que haga de los Contenidos del Sitio Web y de los Servicios ofrecidos en el mismo.</p>
<p>El Usuario se abstendr&aacute; de usar de forma indebida el Sitio Web, entendi&eacute;ndose por tal, la realizaci&oacute;n de acciones, tales como, pero sin limitarse a, las siguientes:</p>
<ul>
<li>Reproducir, copiar o distribuir, con fines comerciales, los Contenido del Sitio Web.</li>
<li>Transformar o modificar los Contenidos del Sitio Web.</li>
<li>Emplear el Sitio Web para cualquier clase de finalidad comercial.</li>
<li>Suministrar datos falsos o incorrectos que puedan inducir a Flattlo o a un tercero, a error.</li>
<li>Enviar o transmitir en los canales tales como pero sin limitarse a Sitio Web, chats y redes sociales contenido obsceno, difamatorio, injuriante, cal&uacute;mniate o discriminatorio contra Flattlo y/o cualquier tercero.</li>
<li>Introducir o difundir en la red virus inform&aacute;ticos o cualquier otro c&oacute;digo de naturaleza destructiva.</li>
<li>Atentar de cualquier forma contra el Sitio Web, su plataforma tecnol&oacute;gica o sus sistemas de informaci&oacute;n.</li>
<li>Alterar, bloquear o realizar cualquier otro acto que impida mostrar alg&uacute;n Contenido o acceder a alg&uacute;n Servicio del Sitio Web.</li>
<li>Realizar ataques inform&aacute;ticos, interceptaci&oacute;n de comunicaciones, uso no autorizado determ&iacute;nales o usurpaci&oacute;n de identidad.</li>
<li>Infringir patentes comerciales, marcas registradas, secretos comerciales e industriales, derechos de publicidad o cualquier otro derecho de personas naturales o jur&iacute;dicas, colectividades, etc.</li>
<li>Impedir o interrumpir el uso del sitio por parte de terceros.</li>
<li>Usar programas autom&aacute;ticos, mecanismos o procesos manuales para monitorear, copiar, resumir, o extraer informaci&oacute;n de cualquier otra forma desde el Sitio Web.</li>
<li>Usar las cuentas y claves de terceros Usuarios, o cualquier otra informaci&oacute;n sin el consentimiento previo y por escrito de su titular.</li>
<li>Transmitir desde este sitio SPAM, cadenas, correo basura o cualquier otro tipo de correo masivo no solicitado.</li>
</ul>
<p><strong>1.5. V&iacute;nculos.</strong></p>
<p>El Sitio Web puede contener hiperv&iacute;nculos o Links que conduzcan a otros sitios web, que no sean controlados, editados, ni tengan relaci&oacute;n alguna con Flattlo, no siendo estos, en consecuencia, responsables del contenido ni de la exactitud de la informaci&oacute;n contenida en ellos.</p>
<p>La funci&oacute;n de los links que se encuentran en el Sitio Web es meramente informativa, es proporcionada por terceros, y se limita s&oacute;lo a dar a conocer al Usuario otras fuentes de informaci&oacute;n relacionadas con las materias propias del Sitio Web. Flattlo tiene responsabilidad alguna respecto de las p&aacute;ginas web que cuenten con links al Sitio Web. El establecimiento de estos v&iacute;nculos no implica en forma alguna la existencia de alg&uacute;n tipo de relaci&oacute;n entre Flattlo y el titular de la p&aacute;gina web en la que se establezcan los mismos.</p>
<p><strong>1.6. Propiedad Intelectual e Industrial.</strong></p>
<p>El contenido, organizaci&oacute;n, gr&aacute;ficas, dise&ntilde;o, compilaci&oacute;n y otros aspectos de Flattlo y Flattlo y su Sitio Web se encuentran protegidos por leyes de Propiedad Intelectual. Su copia, redistribuci&oacute;n, uso o publicaci&oacute;n, total o parcial, est&aacute; prohibida por la ley.</p>
<p>Flattlo hace expresa reserva del ejercicio de todas las acciones, tanto civiles como penales, destinadas al resguardo de sus leg&iacute;timos derechos de propiedad intelectual e industrial.</p>
<p><strong>1.7. Informaci&oacute;n y datos personales que se solicitan</strong></p>
<p>El Usuario reconoce que el ingreso de su informaci&oacute;n y datos personales, lo realiza de manera voluntaria y ante la solicitud de Flattlo para acceder a los Servicios ofrecidos por el Sitio Web. La informaci&oacute;n y datos personales suministrados por el Usuario, permanecer&aacute;n almacenados por Flattlo de conformidad con las condiciones establecidas por la legislaci&oacute;n vigente y ser&aacute;n tratados conforme a la pol&iacute;tica de protecci&oacute;n de datos personales, la cual se encuentra en el sitio web www.Flattlo.com</p>
<p>A trav&eacute;s de los Servicios ofrecidos en todos los canales tales como pero sin limitarse a Sitio Web, redes sociales o chats, Flattlo recolecta informaci&oacute;n y datos personales del Usuario, la cual puede incluir, en todo o en parte seg&uacute;n las necesidades del Servicio, entre otros, los siguientes datos:</p>
<ul>
<li>Nombres y apellidos.</li>
<li>Tipo y n&uacute;mero de identificaci&oacute;n.</li>
<li>Edad.</li>
<li>G&eacute;nero.</li>
<li>Departamento, ciudad, barrio y direcci&oacute;n de residencia.</li>
<li>Fecha de nacimiento.</li>
<li>Tel&eacute;fonos fijos y celulares.</li>
<li>Correo electr&oacute;nico</li>
<li>Preferencias de diferentes servicios</li>
</ul>
<p>Todos los datos personales proporcionados por el Usuario podr&aacute;n ser almacenados y usados para provecho de la misma actividad comercial de los anunciantes en las unidades de negocio de Flattlo LATAM, tales como, pero sin limitarse a: Flattlo y Academia Flattlo.</p>
<p>La informaci&oacute;n ingresada por el Usuario ser&aacute; utilizada por Flattlo para registrarlo en los programas y servicios que prestan terceros, procesar datos y hacer seguimiento a los pedidos, contestar correos electr&oacute;nicos y proporcionar informaci&oacute;n con respecto a su cuenta y respecto de los bienes y servicios contratados o facilitados sin costo a trav&eacute;s de Flattlo y terceros.</p>
<p>Flattlo se reserva el derecho de usar esta informaci&oacute;n para enviar correos electr&oacute;nicos con informaci&oacute;n relativa a su cuenta o de los proyectos y servicios contratados, como tambi&eacute;n para enviar informaci&oacute;n sobre promociones, productos, proyectos y servicios seg&uacute;n la periodicidad definida por Flattlo.</p>
<p>El Usuario, en ejercicio de sus derechos consagrados en la Ley 1581 de 2012, siempre podr&aacute; solicitar el cese de env&iacute;o de los correos electr&oacute;nicos y la supresi&oacute;n de sus datos personales escribiendo al correo electr&oacute;nico hola@Flattlo.com. Flattlo transferir&aacute; a cada tercero que publicista la informaci&oacute;n proporcionada de forma voluntaria por los Usuarios y esta ser&aacute; a su vez, almacenada en la base de datos de Flattlo LATAM.</p>
<p><strong>1.8. Uso de cookies, pixeles y tecnolog&iacute;as similares</strong></p>
<p>Flattlo puede hacer uso de cookies, pixeles y otras tecnolog&iacute;as similares, tanto en el Sitio Web como en el correo electr&oacute;nico del Usuario con la finalidad de, entre otras, registrar las actividades del Usuario en el Sitio Web, mejorar la funcionalidad del mismo, optimizar los Servicios ofrecidos, evaluar la eficacia de la publicidad del mismo, determinar qui&eacute;n ha abierto el correo electr&oacute;nico enviado, as&iacute; como el formato en que lo hace.</p>
<p>Estas herramientas permiten la obtenci&oacute;n de, entre otras, la siguiente informaci&oacute;n:</p>
<ul>
<li>Tipo de navegador y sistema operativo que utiliza el Usuario.</li>
<li>Direcci&oacute;n IP.</li>
<li>Tiempo que permanece en el Sitio Web.</li>
<li>N&uacute;mero de visitas realizadas al Sitio Web.</li>
</ul>
<p>Las cookies son identificadores que nuestro servidor le env&iacute;a a su computador para reconocer el computador que ha sido utilizado durante la visita a nuestro Sitio Web. La mayor&iacute;a de buscadores est&aacute;n dise&ntilde;ados para aceptar estas cookies autom&aacute;ticamente.</p>
<p>El Usuario puede configurar su navegador para deshabilitar y eliminar las cookies, los pixeles y tecnolog&iacute;as similares.</p>
<p><strong>1.9. Calidad de la informaci&oacute;n y datos personales.</strong></p>
<p>El Usuario se compromete a suministrar informaci&oacute;n veraz, completa, exacta y actual, as&iacute; como a comunicar por el Sitio Web, cualquier modificaci&oacute;n de la misma que pudiere afectarla prestaci&oacute;n de los Servicios ofrecidos en el Sitio Web. Flattlo presume la veracidad, completitud, exactitud, actualidad y autenticidad de la informaci&oacute;n y de los datos personales suministrados por el Usuario, y en consecuencia no es responsable por los da&ntilde;os y/o perjuicios, cualquiera sea su naturaleza, que tengan origen en la ausencia de cualquiera de estas caracter&iacute;sticas, incluyendo, pero sin limitarse a, homonimia y suplantaci&oacute;n de la identidad.</p>
<p><strong>1.10. Seguridad de la informaci&oacute;n</strong></p>
<p>Flattlo utiliza estrictos procedimientos de seguridad para garantizar la integridad, confidencialidad y seguridad de la informaci&oacute;n y de los datos personales suministrados por el Usuario, los cuales evitan su alteraci&oacute;n y tratamiento o acceso no autorizado.</p>
<p>En los casos en que, de acuerdo con la pol&iacute;tica de protecci&oacute;n de datos de Flattlo LATAM, esta transfiera la informaci&oacute;n y/o datos personales a un tercero, &eacute;ste estar&aacute; obligado a adoptar procedimientos y medidas similares que garanticen la seguridad de la informaci&oacute;n.</p>
<p><strong>1.11 Derechos del usuario</strong></p>
<p>De conformidad con la legislaci&oacute;n guatemalteca vigente, el Usuario tiene derecho a conocer la informaci&oacute;n y los datos personales almacenados por Flattlo, as&iacute; como los detalles del tratamiento que se le da a los mismos. Igualmente, en ejercicio de los derechos que le asisten, puede solicitar que sean rectificados o actualizados cuando &eacute;stos sean inexactos, incompletos o desactualizados, as&iacute; como a solicitar su eliminaci&oacute;n cuando no se utilicen conforme a la finalidad y en los t&eacute;rminos descritos en la pol&iacute;tica de protecci&oacute;n de datos de Flattlo.</p>
<p>Para el ejercicio de tales derechos, el usuario podr&aacute; enviar una comunicaci&oacute;n al siguiente correo electr&oacute;nico: support@flattlo.com</p>
<ol start="2">
<li><strong> LEY APLICABLE Y JURISDICCI&Oacute;N</strong></li>
</ol>
<p>Las condiciones de uso establecidas en el presente documento, as&iacute; como los conflictos que pudieren surgir respecto de su interpretaci&oacute;n, uso, alcance y terminaci&oacute;n, se rigen por la ley guatemalteca.</p>
<p>Para cualquier efecto legal o judicial, el lugar de las presentes condiciones de uso, es la ciudad de Guatemala, Guatemala.</p>
<p>Si cualquiera de las provisiones de este documento pierde validez o eficacia por cualquier raz&oacute;n, dicha provisi&oacute;n se considerar&aacute; separable de las provisiones restantes de este documento y de ninguna manera afectar&aacute; o perjudicar&aacute; la validez u obligatoriedad de las provisiones restantes.</p>
<ol start="3">
<li><strong> SOLUCI&Oacute;N DE CONTROVERSIAS Y CL&Aacute;USULA COMPROMISORIA</strong></li>
</ol>
<p>Cualquier diferencia que surja con ocasi&oacute;n de la interpretaci&oacute;n, uso, alcance y terminaci&oacute;n de las condiciones de uso establecidas en el presente documento, que no pueda ser solucionada directamente entre el Usuario y Flattlo dentro de los treinta (30) d&iacute;as calendario contados desde la fecha en que cualquiera de ellos de aviso por escrito al otro del surgimiento de la controversia, deber&aacute; ser dirimida mediante procedimiento arbitral y conforme a las siguientes reglas:</p>
<p>3.1 El tribunal sesionar&aacute; en las instalaciones del Centro de Conciliaci&oacute;n y Arbitraje de la C&aacute;mara de Comercio de Guatemala.</p>
<p>3.2 El tribunal estar&aacute; integrado por un (1) &aacute;rbitro en caso de que se trate de asuntos cuyas pretensiones sean iguales o inferiores a cuatrocientos (400) salarios m&iacute;nimos legales mensuales vigentes, o tres (3) &aacute;rbitros en el evento que la cuant&iacute;a de las pretensiones sea mayor. Los &aacute;rbitros deber&aacute;n ser abogados en ejercicio.</p>
<p>3.3 Los &aacute;rbitros ser&aacute;n designados de com&uacute;n acuerdo entre el Usuario y H&aacute;bitat, si no hubiere acuerdo entre ellos para la designaci&oacute;n dentro de los quince (15) d&iacute;as comunes siguientes aquel en que finaliz&oacute; la etapa de arreglo directo indicada al comienzo de esta cl&aacute;usula, la designaci&oacute;n se har&aacute; por sorteo y de las listas del Centro de Conciliaci&oacute;n y Arbitraje de la C&aacute;mara de Comercio de Medell&iacute;n.</p>
<p>3.4 El arbitraje ser&aacute; en derecho</p>
<p>16 de junio de 2017</p>
<p>Gracias por su inter&eacute;s en Flattlo. Le rogamos lea detenidamente los siguientes t&eacute;rminos y condiciones, que regular&aacute;n su registro y uso del Sitio Web www.Flattlo.com (en adelante Flattlo).</p>
<p>Al usar Flattlo acepta los t&eacute;rminos y condiciones (en adelante como Condiciones de servicio) contenidos en el presente documento. Usted accede a aceptar las presentes Condiciones de servicio por v&iacute;a electr&oacute;nica.&nbsp;<strong>Si no desea quedar vinculado por las Condiciones de servicio, no podr&aacute; hacer uso de Flattlo.</strong></p>
<p>Propiedad intelectual:</p>
<p>El Usuario reconoce que todos los elementos de la plataforma y de cada uno de los Servicios prestados a trav&eacute;s del mismo est&aacute;n protegidos por derechos de propiedad intelectual e industrial de Flattlo o de terceros.</p>
<p>Queda terminantemente prohibida la utilizaci&oacute;n de los textos, im&aacute;genes, anuncios y cualquier otro elemento incluido en Flattlo para su posterior inclusi&oacute;n, total o parcial, en otros sitios web ajenos a Flattlo sin contar con la autorizaci&oacute;n previa y por escrito de Flattlo.</p>
<p>El Usuario deber&aacute; utilizar los materiales, elementos e informaci&oacute;n a la que acceda a trav&eacute;s de la utilizaci&oacute;n de Flattlo &uacute;nicamente para uso personal, oblig&aacute;ndose a no realizar ni directa ni indirectamente una explotaci&oacute;n comercial ni de los servicios ni de los materiales, elementos e informaci&oacute;n obtenidos a trav&eacute;s de los mismos.</p>
<p>Informaci&oacute;n Personal:</p>
<p>Durante su registro deber&aacute; configurar el usuario y contrase&ntilde;a para iniciar sesi&oacute;n en su cuenta. Usted es responsable de mantener la confidencialidad de esta informaci&oacute;n y de todas y cada una de las actividades y mensajes que se realicen a trav&eacute;s de su cuenta.</p>
<p>Si extraviara u olvidara su contrase&ntilde;a, por favor, haga clic en el enlace de &ldquo;&iquest;Olvidaste tu contrase&ntilde;a?&rdquo;. Si contin&uacute;a teniendo problemas, p&oacute;ngase en contacto con nuestro personal de servicio t&eacute;cnico en&nbsp;<a href="mailto:support@vecindario.com"><strong>support@Flattlo.com</strong></a></p>
<p>Al registrase en el Sitio Web, e ingresar la Informaci&oacute;n Personal, el Usuario est&aacute; autorizando expresamente a Flattlo a dar a conocer a cualquier autoridad competente la informaci&oacute;n respectiva, en caso de que &eacute;sta sea solicitada por los medios jur&iacute;dicos adecuados.</p>
<p>Calidad de los bienes promocionados:</p>
<p>Toda la informaci&oacute;n acerca de los inmuebles promocionados en Flattlo es proporcionada por agentes inmobiliarios y/o constructoras, siendo por ende de su exclusiva responsabilidad, como as&iacute; tambi&eacute;n toda pauta referida a su comercializaci&oacute;n.</p>
<p>Flattlo Exclusivos no pretende contener una lista &uacute;nica de los bienes promocionados y Flattlo no garantiza de modo alguno que no existan otros bienes en el mercado, incluso m&aacute;s convenientes, en precio o condiciones, como as&iacute; tampoco que no existan otros bienes que cumplan la misma funci&oacute;n que los publicados en la plataforma Flattlo.</p>
<p>En consecuencia, Flattlo sugiere firmemente que la informaci&oacute;n brindada por el Sitio Web respecto de los bienes publicados, sea objeto de una investigaci&oacute;n independiente y propia de quien est&eacute; interesado en la misma, no asumiendo Flattlo ning&uacute;n tipo de responsabilidad por la incorrecci&oacute;n de la informaci&oacute;n, su desactualizaci&oacute;n o falsedad.</p>
<p>Flattlo no asume obligaci&oacute;n alguna respecto del Usuario y se limita tan s&oacute;lo a publicar en la plataforma, en forma similar a lo que har&iacute;a una revista o la secci&oacute;n clasificados de un peri&oacute;dico impreso, los datos de los Usuarios oferentes de bienes que han solicitado tal publicaci&oacute;n y en la forma en que tales datos han sido proporcionados por tales Usuarios.</p>
<p>Flattlo no resulta propietario de los bienes y servicios que se publicitan en el Sitio Web, y por ende no garantiza en forma alguna dichos bienes y servicios, ya sea respecto de su calidad, condiciones de entrega, existencia, cr&eacute;dito, capacidad ni sobre ning&uacute;n otro aspecto de los Usuarios proveedores de tales bienes y servicios.</p>
<p>En consecuencia, el contrato de compraventa/locaci&oacute;n de bienes o la contrataci&oacute;n de los servicios parte de los Usuarios con los oferentes de los bienes se realiza directamente entre el oferente y quien resulte adquirente del bien o servicio por aqu&eacute;l ofrecido y fuera de la esfera de participaci&oacute;n de Flattlo y sin su intervenci&oacute;n.</p>
<p>Los Usuarios aceptan y reconocen que Flattlo no controla, ni supervisa, el cumplimiento de los requisitos legales para ofrecer y vender los bienes o servicios, ni sobre la capacidad y legitimaci&oacute;n de los Usuarios oferentes para promocionar, ofrecer, vender y/o alquilar sus bienes o servicios.</p>
<p>El Usuario reconoce y acepta que cualquier relaci&oacute;n contractual o extracontractual que formalice con los anunciantes o terceras personas contactadas a trav&eacute;s del Sitio Web, as&iacute; como su participaci&oacute;n en concursos, promociones, compraventa de bienes o servicios, se entienden realizados &uacute;nica y exclusivamente entre el Usuario y el anunciante y/o tercera persona. En consecuencia, el Usuario acepta que Flattlo no tiene ning&uacute;n tipo de responsabilidad sobre los da&ntilde;os o perjuicios de cualquier naturaleza ocasionados con motivo de sus negociaciones, conversaciones y/o relaciones contractuales o extracontractuales con los anunciantes o terceras personas f&iacute;sicas o jur&iacute;dicas contactadas a trav&eacute;s del Sitio Web.</p>
<p>Flattlo podr&aacute; cambiar estas Condiciones de servicio en cualquier momento y a su total discreci&oacute;n sin previo aviso, las cuales ser&aacute;n de efecto inmediato tras su publicaci&oacute;n y usted est&aacute; de acuerdo con las nuevas condiciones de servicio al continuar su uso de la Plataforma Flattlo. Si no est&aacute; de acuerdo con las Condiciones de servicio modificadas, su &uacute;nica opci&oacute;n ser&aacute; dejar de hacer uso de la Plataforma Flattlo.</p>
<p><strong>EL USUARIO RECONOCE HABER LE&Iacute;DO EL PRESENTE ACUERDO, LO COMPRENDE Y ACEPTA QUEDAR VINCULADO POR SUS T&Eacute;RMINOS Y CONDICIONES.</strong></p>
    </div>
  </vs-col>
  <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="2" vs-sm="4" vs-xs="12">
    
  </vs-col>
</vs-row>
       
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>